import BaseModel from '@/api/models/BaseModel';
import { RateDataRowInterface } from '@/domains/pd-admin/types/administration/ratings-and-reviews/rating-and-reviews';

class RatesResponseModel extends BaseModel {
  public rates: RateDataRowInterface[];
  constructor(arg: any) {
    super(arg);
    this.rates = arg.rates;
  }
}

export default RatesResponseModel;
