import { ApplicationListingStatusProperty, ApplicationTypeProperty } from '@/api/models/ApplicationModel';

export interface FeaturedAppsStateInterface {
  appSelection: null | FeaturedAppDataRowInterface;
  orderSelection: null | AppOrderInterface;
  labelAr: null | string;
  labelEn: null | string;
}

export interface FeaturedAppDataRowInterface {
  id: number;
  name: string;
  name_ar: string;
  unique_id: null | string;
  contact_email: string;
  redirect_uri: string;
  icon: string;
  cover_photo: null | string;
  description: string;
  short_description: string;
  description_ar: null;
  short_description_ar: string;
  screen_shots: null | string[];
  screen_shots_ar: null | string[];
  screen_shots_en: null | string[];
  is_installed: boolean;
  rate: number;
  is_featured: boolean;
  featured_until: null | string;
  price: number;
  total_voters: null | number;
  url: string;
  installation_url: string;
  language: string;
  video: null | string;
  type: ApplicationTypeProperty;
  created_at: string;
  under_review_at: null | string;
  published_at: null | string;
  last_upload_contract_date: null | string;
  steps: number;
  scopes_note: null | string;
  install_instructions: null | string;
  is_html_enabled: boolean;
  developer_name_en: null | string;
  developer_name_ar: null | string;
  listing_status: ApplicationListingStatusProperty;
  total_app_installed: null | number;
  label: string;
  label_ar: string;
  category: {
    id: number;
    name_ar: string;
    name_en: string;
  };
  publisher: {
    id: number;
    name: string;
    email: string;
  };
  client_id: number;
  client_secret: string;
  order: number;
}

export interface AppOrderInterface {
  label: AppOrdersEnum;
  value: AppOrdersValuesEnum;
}

export enum AppOrdersEnum {
  FirstApp = 'First App',
  SecondApp = 'Second App',
  ThirdApp = 'Third App',
  FourthApp = 'Fourth App',
  FifthApp = 'Fifth App',
}
export enum AppOrdersValuesEnum {
  FirstApp = 0,
  SecondApp = 1,
  ThirdApp = 2,
  FourthApp = 3,
  FifthApp = 4,
}
