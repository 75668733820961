import ErrorModel from '@/api/models/ErrorModel';
import { getAllCollections } from '@/domains/pd-admin/api/administration/apps-collections/collection-list/collection-list';
import {
  CollectionInterface,
  ConfirmDeleteInterface,
} from '@/domains/pd-admin/types/administration/apps-collections/collections-list/collectionsList.type';
import { reactive, onBeforeMount, ref } from '@vue/composition-api';

const useCollectionsList = () => {
  const isLoadingCollectionsList = ref(false);

  const originalCollections = reactive<Record<string, CollectionInterface[]>>({
    list: [],
  });

  const confirmDeleteCollectionState = reactive<ConfirmDeleteInterface>({
    id: null,
    isOpen: false,
    isLoading: false,
    title: 'Are you sure you want to delete this collection?',
  });

  const resetConfirmDeleteModalState = () => {
    confirmDeleteCollectionState.id = null;
    confirmDeleteCollectionState.isOpen = false;
    confirmDeleteCollectionState.isLoading = false;
  };

  onBeforeMount(() => {
    fetchCollections();
  });

  const fetchCollections = async () => {
    isLoadingCollectionsList.value = true;
    const res = await getAllCollections();
    if (res instanceof ErrorModel) {
      isLoadingCollectionsList.value = false;
      return;
    }
    originalCollections.list = [...res.collections];
    isLoadingCollectionsList.value = false;
  };

  return {
    isLoadingCollectionsList,
    originalCollections,
    confirmDeleteCollectionState,
    resetConfirmDeleteModalState,
    fetchCollections,
  };
};

export default useCollectionsList;
