import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import RatesResponseModel from '@/domains/pd-admin/models/administration/ratings-and-reviews/rates-response-model';

const ENDPOINT_URL = '/market';

export const getAllRatings = () => {
  return httpClient
    .get(`${ENDPOINT_URL}/admin/ratings`)
    .then((response) => new RatesResponseModel(response))
    .catch((error) => error);
};

export const filterRatingsByApps = (appsIDs: number[]) => {
  let param = '';
  if (appsIDs.length) {
    param = `?appsIds=${appsIDs.join(',')}`;
  }
  return httpClient
    .get(`${ENDPOINT_URL}/admin/ratings${param}`)
    .then((response) => new RatesResponseModel(response))
    .catch((error) => error);
};

export const approveRating = (ratingID: number) => {
  const data = {
    rating_id: ratingID,
    isDraft: 0,
  };
  return httpClient
    .post(`${ENDPOINT_URL}/admin/ratings/${ratingID}/approve`, data)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
