import httpClient from '@/api/httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import {
  AdminAppsFilterParamsType,
  AdminAppsFilterParamsInterface,
} from '@/domains/pd-admin/types/administration/apps-management/apps-management.type';
import AppsManagementResponseModel from '../../../models/administration/apps-management/apps-management-response-model';

const ENDPOINT_URL = '/market/admin';

export const getAllAdminApps = (): Promise<AppsManagementResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}`)
    .then((response) => new AppsManagementResponseModel(response))
    .catch((error) => error);
};

export const filterAdminApps = (
  filters: AdminAppsFilterParamsType,
): Promise<AppsManagementResponseModel | ErrorModel> => {
  const filterParams: AdminAppsFilterParamsInterface = {};

  if (filters.searchValue) {
    filterParams.search = filters.searchValue;
  }
  if (filters.appStatus) {
    filterParams.status = filters.appStatus;
  }
  if (filters.appType || filters.appType === 0) {
    filterParams.type = filters.appType;
  }

  return httpClient
    .get(`${ENDPOINT_URL}`, {
      params: filterParams,
    })
    .then((response) => new AppsManagementResponseModel(response))
    .catch((error) => error);
};
