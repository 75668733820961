import BaseModel from '@/api/models/BaseModel';
import { FeaturedAppDataRowInterface } from '@/domains/pd-admin/types/administration/featured-apps/featured-apps.type';

class FeaturedAppsResponseModel extends BaseModel {
  public featured_apps: FeaturedAppDataRowInterface[];
  constructor(arg: any) {
    super(arg);
    this.featured_apps = arg.featured_apps;
  }
}

export default FeaturedAppsResponseModel;
