import httpClient from '@/api/httpClient';
import FeaturedAppsResponseModel from '@/domains/pd-admin/models/administration/featured-apps/featured-apps-response-model';
import UpdateDeleteAppResponseModel from '@/domains/pd-admin/models/administration/featured-apps/update-delete-app-response-model';

const ENDPOINT_URL = '/market';

export const getFeaturedApps = () => {
  return httpClient
    .get(`${ENDPOINT_URL}/admin/featured`)
    .then((response) => new FeaturedAppsResponseModel(response))
    .catch((error) => error);
};

export const AddOrUpdateFeaturedApps = (appID: number, order: number, label: Record<string, string>) => {
  const formData = new FormData();
  formData.append('is_featured', `1`);
  formData.append('order', `${order}`);
  formData.append(`label_ar`, label.ar || '');
  formData.append(`label`, label.en || '');
  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${appID}/featuredApp`, formData)
    .then((response) => new UpdateDeleteAppResponseModel(response))
    .catch((error) => error);
};

export const deleteFeaturedApp = (appID: number) => {
  const formData = new FormData();
  formData.append('is_featured', `0`);
  formData.append('order', `0`);
  return httpClient
    .post(`${ENDPOINT_URL}/admin/update/${appID}/featuredApp`, formData)
    .then((response) => new UpdateDeleteAppResponseModel(response))
    .catch((error) => error);
};
