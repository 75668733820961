import ApplicationModel from '@/api/models/ApplicationModel';

export interface SuggestedAppsStateInterface {
  appsSelection: ApplicationModel[];
}

export enum CategorySelectModeEnum {
  AUTOMATIC = 1,
  MANUAL = 0,
}

export interface CategoryItemControlledInterface {
  id: number;
  name_ar: string;
  name_en: string;
  inReorderMode: boolean;
  suggested_apps: {
    suggested_ids: number[];
    is_auto: 0 | 1;
    apps: ApplicationModel[];
  };
}
