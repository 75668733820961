import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import router from '@/router';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import ErrorModel from '@/api/models/ErrorModel';
import {
  ZidLoader,
  ZidButton,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
  ZidPageHeader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidInput,
  ZidIcon,
  ZidInputGroup,
  ZidInputTrailing,
  ZidSelect,
  ZidSelectBody,
  ZidSelectHeader,
  ZidSelectOption,
  ZidFileSelector,
  ZidTextarea,
  ZidEditor,
} from '@zidsa/ui';
import VueEasymde from 'vue-easymde';
import { RoutingRouteEnum } from '@/router/routes.enum';
import TabMultiLanguage from '@/components/tab-multi-language/TabMultiLanguage.vue';
import useManageCollection from '@/domains/pd-admin/helpers/administration/apps-collections/manage-collection/useManageCollection';
import { updateCollection } from '@/domains/pd-admin/api/administration/apps-collections/collection-list/collection-list';
import {
  CollectionInfoInterface,
  CollectionStatusEnum,
} from '@/domains/pd-admin/types/administration/apps-collections/manage-collection/manageCollection.type';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    TabMultiLanguage,
    ZidInput,
    ZidIcon,
    ZidInputGroup,
    ZidInputTrailing,
    ZidSelect,
    ZidSelectBody,
    ZidSelectHeader,
    ZidSelectOption,
    ZidFileSelector,
    ZidTextarea,
    ZidEditor,
    VueEasymde,
  },
  setup(_, ctx) {
    const {
      manageCollectionState,
      collectionOrderDropdown,
      v$,
      isLoadingCollection,
      isEditMode,
      isSaveDraftLoading,
      isPublishLoading,
      initialCollectionImage,
      isPublishDisabled,
      isImageLoading,
      isImageInvalid,
      collectionImage,
      isImageFileValid,
      ManageCollectionInputPropertiesEnum,
      ManageCollectionSelectPropertiesEnum,
      onCollectionImageChange,
      onCollectionImageRemove,
      onInputDataChange,
      onSelectionDataChange,
    } = useManageCollection(parseInt(ctx.root.$route.params.id || '0'));

    const articleMarkdownConfigs = {
      toolbar: [
        'bold',
        'italic',
        'strikethrough',
        '|',
        'heading',
        '|',
        'heading-smaller',
        '|',
        'heading-bigger',
        '|',
        'quote',
        'code',
        '|',
        'unordered-list',
        'ordered-list',
        '|',
        'link',
        '|',
        'image',
        '|',
        'clean-block',
        '|',
        'guide',
      ],
      spellChecker: false,
      status: [],
    };

    const onCancelClicked = () => {
      router.push({ name: RoutingRouteEnum.Admin_AppsCollections }).catch(() => {
        //
      });
    };

    const onSaveDraftClicked = async () => {
      isSaveDraftLoading.value = true;
      const collectionInfo: CollectionInfoInterface = {
        name: manageCollectionState.collectionNameEn,
        name_ar: manageCollectionState.collectionNameAr,
        description: manageCollectionState.collectionArticleEn,
        description_ar: manageCollectionState.collectionArticleAr,
        short_description: manageCollectionState.collectionShortDescriptionEn,
        short_description_ar: manageCollectionState.collectionShortDescriptionAr,
        label: manageCollectionState.collectionLabelEn,
        label_ar: manageCollectionState.collectionLabelAr,
        quote: manageCollectionState.collectionQuoteEn,
        quote_ar: manageCollectionState.collectionQuoteAr,
        image: collectionImage.image instanceof File ? collectionImage.image : null,
        order: manageCollectionState.collectionOrder.value,
        apps: manageCollectionState.collectionAppsIDs.split(','),
        status: CollectionStatusEnum.Draft,
      };

      if (isEditMode.value) collectionInfo.id = Number(ctx.root.$route.params.id);

      const res = await updateCollection(collectionInfo);
      if (res instanceof ErrorModel) {
        Vue.$toast.error('Could not save draft');
        isSaveDraftLoading.value = false;
        return;
      }

      Vue.$toast.success('Saved as draft');
      isSaveDraftLoading.value = false;
      router
        .push({
          name: RoutingRouteEnum.Admin_AppsCollections,
        })
        .catch(() => {
          //
        });
    };

    const onPublishCollectionClicked = async () => {
      isPublishLoading.value = true;
      const collectionInfo: CollectionInfoInterface = {
        name: manageCollectionState.collectionNameEn,
        name_ar: manageCollectionState.collectionNameAr,
        description: manageCollectionState.collectionArticleEn,
        description_ar: manageCollectionState.collectionArticleAr,
        short_description: manageCollectionState.collectionShortDescriptionEn,
        short_description_ar: manageCollectionState.collectionShortDescriptionAr,
        label: manageCollectionState.collectionLabelEn,
        label_ar: manageCollectionState.collectionLabelAr,
        quote: manageCollectionState.collectionQuoteEn,
        quote_ar: manageCollectionState.collectionQuoteAr,
        image: collectionImage.image instanceof File ? collectionImage.image : null,
        order: manageCollectionState.collectionOrder.value,
        apps: manageCollectionState.collectionAppsIDs.split(','),
        status: CollectionStatusEnum.Published,
      };

      if (isEditMode.value) collectionInfo.id = Number(ctx.root.$route.params.id);

      const res = await updateCollection(collectionInfo);
      if (res instanceof ErrorModel) {
        Vue.$toast.error('Could not publish collection');
        isPublishLoading.value = false;
        return;
      }

      Vue.$toast.success('Collection has been published');
      isPublishLoading.value = false;
      router
        .push({
          name: RoutingRouteEnum.Admin_AppsCollections,
        })
        .catch(() => {
          //
        });
    };

    return {
      RoutingRouteEnum,
      manageCollectionState,
      collectionOrderDropdown,
      v$,
      isLoadingCollection,
      isEditMode,
      isSaveDraftLoading,
      isPublishLoading,
      initialCollectionImage,
      isPublishDisabled,
      isImageLoading,
      isImageInvalid,
      collectionImage,
      isImageFileValid,
      articleMarkdownConfigs,
      ManageCollectionInputPropertiesEnum,
      ManageCollectionSelectPropertiesEnum,
      onCollectionImageChange,
      onCollectionImageRemove,
      onInputDataChange,
      onSelectionDataChange,
      onCancelClicked,
      onSaveDraftClicked,
      onPublishCollectionClicked,
    };
  },
});
