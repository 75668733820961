export enum AppTypesKeysEnum {
  PublicApps = 'public_apps',
  ShippingApps = 'shipping_apps',
}

export enum AppTypeProperty {
  OAuth = 'OAuth',
  Javascript = 'Javascript',
  Shipping = 'Shipping',
}
