import ApplicationModel from '@/api/models/ApplicationModel';
import BaseModel from '@/api/models/BaseModel';

class CategoriesListResponseModel extends BaseModel {
  public payload: CategoryItemInterface[];
  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default CategoriesListResponseModel;

export interface CategoryItemInterface {
  id: number;
  name_ar: string;
  name_en: string;
  suggested_apps: {
    suggested_ids: number[];
    is_auto: 0 | 1;
    apps: ApplicationModel[];
  };
}
