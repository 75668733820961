import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import CategoriesListResponseModel from '@/domains/pd-admin/models/administration/suggested-apps/categories-list-response-model';
import { CategorySelectModeEnum } from '@/domains/pd-admin/types/administration/suggested-apps/suggested-apps';

const ENDPOINT_URL = '/market/admin/suggested-apps';

export const getCategories = (): Promise<CategoriesListResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}`)
    .then((response) => new CategoriesListResponseModel(response))
    .catch((error) => error);
};

export const updateCategory = (
  categoryID: number,
  selectMode: CategorySelectModeEnum,
  suggestedAppsIDs: number[],
): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .post(`${ENDPOINT_URL}/${categoryID}`, {
      is_auto: selectMode,
      suggested_ids: suggestedAppsIDs,
    })
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};
