export enum CollectionStatusDropdownEnum {
  Published = 'Published',
  Draft = 'Draft',
}

export enum CollectionStatusDropdownValuesEnum {
  Published = 'published',
  Draft = 'draft',
}

export interface ManageCollectionStateInterface {
  collectionNameAr: string;
  collectionNameEn: string;
  collectionOrder: {
    label: string;
    value: string;
  };
  collectionCardImage: string | File;
  collectionShortDescriptionAr: string;
  collectionShortDescriptionEn: string;
  collectionLabelAr: string;
  collectionLabelEn: string;
  collectionArticleAr: string;
  collectionArticleEn: string;
  collectionQuoteAr: string;
  collectionQuoteEn: string;
  collectionAppsIDs: string; // comma separated
}

export enum ManageCollectionInputPropertiesEnum {
  CollectionNameAr = 'collectionNameAr',
  CollectionNameEn = 'collectionNameEn',
  CollectionShortDescriptionAr = 'collectionShortDescriptionAr',
  CollectionShortDescriptionEn = 'collectionShortDescriptionEn',
  CollectionLabelAr = 'collectionLabelAr',
  CollectionLabelEn = 'collectionLabelEn',
  CollectionArticleAr = 'collectionArticleAr',
  CollectionArticleEn = 'collectionArticleEn',
  CollectionQuoteAr = 'collectionQuoteAr',
  CollectionQuoteEn = 'collectionQuoteEn',
  CollectionAppsIDs = 'collectionAppsIDs',
}

export enum ManageCollectionSelectPropertiesEnum {
  CollectionOrder = 'collectionOrder',
}

export enum CollectionStatusEnum {
  Draft = 'draft',
  Published = 'published',
}
export interface CollectionInfoInterface {
  id?: number;
  status: CollectionStatusEnum;
  name: string;
  name_ar: string;
  description: string;
  description_ar: string;
  short_description: string;
  short_description_ar: string;
  label: string;
  label_ar: string;
  quote: string;
  quote_ar: string;
  image: File | null;
  order: string;
  apps: string[];
}
