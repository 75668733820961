import Vue from 'vue';
import { defineComponent, onBeforeMount, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidInput,
  ZidSelect,
  ZidSelectHeader,
  ZidSelectBody,
  ZidSelectOption,
  ZidLoader,
  ZidButton,
  ZidPagination,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import ApproveIcon from '@/assets/icons/domains/pd-admin/ApproveIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import { ApplicationListingStatusProperty, ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import useRatingsAndReviews from '@/domains/pd-admin/helpers/administration/rating-and-reviews/useRatingAndReviews';
import {
  approveRating,
  getAllRatings,
} from '@/domains/pd-admin/api/administration/ratings-and-reviews/ratings-and-reviews';
import Multiselect from 'vue-multiselect';
import { getAllAdminApps } from '@/domains/pd-admin/api/administration/apps-management/apps-management';
import { RateDataRowInterface } from '@/domains/pd-admin/types/administration/ratings-and-reviews/rating-and-reviews';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidInput,
    ZidSelect,
    ZidSelectHeader,
    ZidSelectBody,
    ZidSelectOption,
    ApproveIcon,
    EditIcon,
    ZidLoader,
    ZidButton,
    ZidPagination,
    Multiselect,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup() {
    const loadingAppsList = ref(false);

    const {
      appsOptions,
      ratingsAndReviewsState,
      sortingState,
      resultPerPage,
      v$,
      originalRates,
      filteredRates,
      displayedRates,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      sortingStateProperties,
      isLoading,
      RatingsAndReviewsSelectProperties,
      onSelectionDataChange,
      onSortASC,
      onSortDEC,
      onPreviousClick,
      onNextClick,
    } = useRatingsAndReviews();

    onBeforeMount(async () => {
      await getRatings();

      // all apps
      loadingAppsList.value = true;
      const allAppsRes = await getAllAdminApps();
      if (allAppsRes instanceof ErrorModel || !allAppsRes.payload || !(allAppsRes.payload instanceof Array)) {
        return;
      }
      appsOptions.options = [...allAppsRes.payload];
      loadingAppsList.value = false;
      // all apps
    });

    const getRatings = async () => {
      isLoading.value = true;
      const response = await getAllRatings();
      if (!response.rates) {
        isLoading.value = false;
        return;
      }
      originalRates.list = response.rates;
      const initialTableRecordCount = response.rates.length >= 10 ? 10 : response.rates.length;
      displayedRates.list = response.rates.slice(0, initialTableRecordCount);
      currentFilterRange.startIndex = 0;
      currentFilterRange.endIndex = initialTableRecordCount;
      isLoading.value = false;
    };

    const onApproveClicked = async (rate: RateDataRowInterface) => {
      const res = await approveRating(rate.id);
      if (res.status !== 'success') {
        Vue.$toast.error('Failed to approve this review');
        return;
      }
      Vue.$toast.success('Review has been approved');
      ratingsAndReviewsState.appsSelection = [];
    };

    return {
      appsOptions,
      ratingsAndReviewsState,
      sortingState,
      resultPerPage,
      v$,
      originalRates,
      filteredRates,
      displayedRates,
      currentFilterRange,
      isFilterApplied,
      isNextButtonDisabled,
      ApplicationListingStatusProperty,
      sortingStateProperties,
      ApplicationTypeProperty,
      isLoading,
      loadingAppsList,
      RatingsAndReviewsSelectProperties,
      onSelectionDataChange,
      onSortASC,
      onSortDEC,
      onPreviousClick,
      onNextClick,
      onApproveClicked,
    };
  },
});
