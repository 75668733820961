import Vue from 'vue';
import { defineComponent, ref, watch } from '@vue/composition-api';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import {
  ZidButton,
  ZidIcon,
  ZidLoader,
  ZidFileSelector,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
  ZidInput,
} from '@zidsa/ui';
import BaseText from '@/components/text/BaseText.vue';
import useFeaturedApps from '@/domains/pd-admin/helpers/administration/featured-apps/useFeaturedApps';
import Multiselect from 'vue-multiselect';
import {
  AppOrderInterface,
  AppOrdersEnum,
  AppOrdersValuesEnum,
  FeaturedAppDataRowInterface,
} from '@/domains/pd-admin/types/administration/featured-apps/featured-apps.type';
import {
  AddOrUpdateFeaturedApps,
  deleteFeaturedApp,
  getFeaturedApps,
} from '@/domains/pd-admin/api/administration/featured-apps/featured-apps';
import { getAllAdminApps } from '@/domains/pd-admin/api/administration/apps-management/apps-management';
import ErrorModel from '@/api/models/ErrorModel';
import { ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import TabMultiLanguage from '../../../../../components/tab-multi-language/TabMultiLanguage.vue';

export default defineComponent({
  components: {
    BaseIcon,
    EditIcon,
    DeleteIcon,
    ZidButton,
    ZidIcon,
    BaseText,
    ZidLoader,
    Multiselect,
    ZidFileSelector,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    TabMultiLanguage,
    ZidInput,
  },
  setup() {
    const isSaveLoading = ref(false);
    const subscriptionAppsSize = ref(0);
    const ShippingAppsSize = ref(0);

    const {
      featuredAppsState,
      v$,
      isLoadingAppsList,
      showAddOrEditFeaturedApp,
      showAddOrEditShippingFeaturedApp,
      featuredAppsList,
      originalApps,
      filteredApps,
      filteredShippingApps,
      appsOrders,
      shippingAppsOrders,
      isEditMode,
      isShippingApp,
      APPS_ORDERS,
      SHIPPING_APPS_ORDERS,
      getFeaturedList,
      updateAppsOderListForSelection,
      updateFilteredAppsListForSelection,
      onInputDataChange,
      updateShippingAppsOderListForSelection,
      updateFilteredShippingAppsListForSelection,
    } = useFeaturedApps();

    const onFeaturedAppDelete = async (application: FeaturedAppDataRowInterface) => {
      isLoadingAppsList.value = true;
      const res = await deleteFeaturedApp(application.id);
      if (res.status !== 'success') {
        isLoadingAppsList.value = false;
        Vue.$toast.error('Failed to delete application');
        return;
      }
      await getFeaturedList();
      const appIndex = featuredAppsList.list.findIndex((app) => app.id === application.id);
      if (appIndex !== -1) {
        featuredAppsList.list.splice(appIndex, 1);
        if (application.type !== ApplicationTypeProperty.Shipping) {
          appsOrders.list.unshift(
            APPS_ORDERS.find((order) => order.value === application.order) || {
              label: AppOrdersEnum.FirstApp,
              value: AppOrdersValuesEnum.FirstApp,
            },
          );
        } else {
          shippingAppsOrders.list.unshift(
            SHIPPING_APPS_ORDERS.find((order) => order.value === application.order) || {
              label: AppOrdersEnum.FirstApp,
              value: AppOrdersValuesEnum.FirstApp,
            },
          );
        }
        updateAppsOderListForSelection();
        updateFilteredAppsListForSelection();
        updateFilteredShippingAppsListForSelection();
      }

      isLoadingAppsList.value = false;
      Vue.$toast.success('Deleted application successfully');
    };

    const onFeaturedAppEdit = async (application: FeaturedAppDataRowInterface) => {
      if (showAddOrEditFeaturedApp.value || showAddOrEditShippingFeaturedApp.value) {
        onCancelNewFeaturedAppBtnClicked();
      }

      if (application.type === ApplicationTypeProperty.Shipping) {
        isShippingApp.value = true;
        featuredAppsState.labelAr = application.label_ar;
        featuredAppsState.labelEn = application.label;
      }

      featuredAppsState.appSelection = application;

      if (application.type !== ApplicationTypeProperty.Shipping) {
        featuredAppsState.orderSelection =
          APPS_ORDERS.find((order: AppOrderInterface) => {
            return order.value === application.order;
          }) || null;

        if (featuredAppsState.orderSelection) {
          updateAppsOderListForSelection();
          if (!appsOrders.list.includes(featuredAppsState.orderSelection)) {
            appsOrders.list = [...appsOrders.list, featuredAppsState.orderSelection];
          }
        }
      } else {
        featuredAppsState.orderSelection =
          SHIPPING_APPS_ORDERS.find((order: AppOrderInterface) => {
            return order.value === application.order;
          }) || null;

        if (featuredAppsState.orderSelection) {
          updateShippingAppsOderListForSelection();
          if (!shippingAppsOrders.list.includes(featuredAppsState.orderSelection)) {
            shippingAppsOrders.list = [...shippingAppsOrders.list, featuredAppsState.orderSelection];
          }
        }
      }

      isEditMode.value = true;

      if (application.type === ApplicationTypeProperty.Shipping) showAddOrEditShippingFeaturedApp.value = true;
      else showAddOrEditFeaturedApp.value = true;
    };

    const onAddNewFeaturedAppBtnClicked = () => {
      isEditMode.value = false;
      if (showAddOrEditShippingFeaturedApp.value) showAddOrEditShippingFeaturedApp.value = false;
      updateAppsOderListForSelection();
      showAddOrEditFeaturedApp.value = true;
    };

    const onAddNewFeaturedShippingAppBtnClicked = () => {
      isEditMode.value = false;
      if (showAddOrEditFeaturedApp.value) showAddOrEditFeaturedApp.value = false;
      updateShippingAppsOderListForSelection();
      showAddOrEditShippingFeaturedApp.value = true;
    };

    const onCancelNewFeaturedAppBtnClicked = () => {
      showAddOrEditFeaturedApp.value = false;
      featuredAppsState.appSelection = null;
      featuredAppsState.orderSelection = null;
      featuredAppsState.labelAr = null;
      featuredAppsState.labelEn = null;
      isEditMode.value = false;
    };

    const onSaveNewFeaturedAppBtnClicked = async () => {
      isSaveLoading.value = true;

      if (featuredAppsState.appSelection && featuredAppsState.orderSelection) {
        showAddOrEditFeaturedApp.value = false;
        showAddOrEditShippingFeaturedApp.value = false;

        const res = await AddOrUpdateFeaturedApps(
          featuredAppsState.appSelection.id,
          featuredAppsState.orderSelection?.value,
          {
            ar: featuredAppsState.labelAr || '',
            en: featuredAppsState.labelEn || '',
          },
        );

        if (res.status !== 'success') {
          Vue.$toast.error('Failed update featured apps');
          return;
        }

        Vue.$toast.success('Updated featured apps successfully');
        // fetured apps
        const featuredAppsRes = await getFeaturedApps();
        if (!featuredAppsRes.featured_apps) {
          isLoadingAppsList.value = false;
          featuredAppsList.list = [];
          return;
        }
        featuredAppsList.list = [...featuredAppsRes.featured_apps].sort((a, b) => a - b);
        updateAppsOderListForSelection();
        updateFilteredAppsListForSelection();
        // fetured apps
        isLoadingAppsList.value = false;

        // all apps
        const allAppsRes = await getAllAdminApps();
        if (allAppsRes instanceof ErrorModel || !allAppsRes.payload) {
          return;
        }
        originalApps.list = [...allAppsRes.payload];
        updateFilteredAppsListForSelection();
        updateFilteredShippingAppsListForSelection();
        // all apps
      }
      featuredAppsState.appSelection = null;
      featuredAppsState.orderSelection = null;
      featuredAppsState.labelAr = null;
      featuredAppsState.labelEn = null;
      isEditMode.value = false;
      isSaveLoading.value = false;
    };

    watch(
      featuredAppsList,
      (newList) => {
        subscriptionAppsSize.value = 0;
        ShippingAppsSize.value = 0;
        newList.list.forEach((app) => {
          if (app.type !== ApplicationTypeProperty.Shipping) subscriptionAppsSize.value++;
          else ShippingAppsSize.value++;
        });
      },
      { deep: true },
    );

    return {
      featuredAppsState,
      v$,
      isLoadingAppsList,
      showAddOrEditFeaturedApp,
      showAddOrEditShippingFeaturedApp,
      featuredAppsList,
      originalApps,
      filteredApps,
      filteredShippingApps,
      appsOrders,
      shippingAppsOrders,
      isEditMode,
      isShippingApp,
      isSaveLoading,
      ApplicationTypeProperty,
      subscriptionAppsSize,
      ShippingAppsSize,
      onFeaturedAppDelete,
      onFeaturedAppEdit,
      onAddNewFeaturedAppBtnClicked,
      onAddNewFeaturedShippingAppBtnClicked,
      onCancelNewFeaturedAppBtnClicked,
      onSaveNewFeaturedAppBtnClicked,
      onInputDataChange,
    };
  },
});
