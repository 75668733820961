import { reactive, watch, ref, computed } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import {
  RatingsAndReviewsSelectProperties,
  RatingsAndReviewsStateInterface,
  SortingStateType,
  sortingStateProperties,
  RateDataRowInterface,
} from '@/domains/pd-admin/types/administration/ratings-and-reviews/rating-and-reviews';
import { ApplicationDataRowInterface } from '@/domains/pd-admin/types/administration/apps-management/apps-management.type';
import { filterRatingsByApps } from '@/domains/pd-admin/api/administration/ratings-and-reviews/ratings-and-reviews';

const useRatingsAndReviews = () => {
  const appsOptions: Record<string, ApplicationDataRowInterface[]> = reactive({ options: [] });

  const ratingsAndReviewsState = reactive<RatingsAndReviewsStateInterface>({
    selectedResultPerPage: {
      label: '10',
      value: '10',
    },
    appsSelection: [],
  });

  const rules = {
    selectedResultPerPage: {
      label: {},
      value: {},
    },
    appsSelection: {},
  };

  const RESULT_PER_PAGE = [];

  for (let i = 10; i <= 100; i += 10) {
    RESULT_PER_PAGE.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const resultPerPage = reactive({
    options: RESULT_PER_PAGE,
  });

  const sortingState = reactive<SortingStateType>({
    rate: null,
    totalRating: null,
  });

  const originalRates = reactive<Record<string, RateDataRowInterface[]>>({
    list: [],
  });

  const filteredRates = reactive<Record<string, RateDataRowInterface[]>>({
    list: [],
  });

  const displayedRates = reactive<Record<string, RateDataRowInterface[]>>({
    list: [],
  });

  const currentFilterRange = reactive<any>({
    startIndex: -1,
    endIndex: -1,
  });

  const isFilterByApps = ref(false);

  const isFilterBySearch = ref(false);

  const isFilterApplied = ref(false);

  const isLoading = ref(false);

  const v$ = useVuelidate(rules, ratingsAndReviewsState);

  const onSelectionDataChange = (changedValue: Record<string, string>, property: RatingsAndReviewsSelectProperties) => {
    v$.value[property].$touch();
    const selectedValue = JSON.parse(JSON.stringify(changedValue));
    ratingsAndReviewsState[property].label = selectedValue.label;
    ratingsAndReviewsState[property].value = selectedValue.value;
  };

  watch(
    () => filteredRates,
    () => {
      applyPageRangeFilter();
    },
    { deep: true },
  );

  watch([isFilterByApps, isFilterBySearch], ([newFilterByApps, newFilterBySearch]) => {
    isFilterApplied.value = newFilterByApps || newFilterBySearch;
    applyPageRangeFilter();
  });

  watch(
    () => ratingsAndReviewsState.selectedResultPerPage,
    (newCountOfResultPerPage) => {
      if (originalRates.list.length >= Number(newCountOfResultPerPage.value)) {
        displayedRates.list = !isFilterApplied.value
          ? originalRates.list.slice(0, Number(newCountOfResultPerPage.value))
          : filteredRates.list.slice(0, Number(newCountOfResultPerPage.value));
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = Number(newCountOfResultPerPage.value);
      } else {
        displayedRates.list = !isFilterApplied.value
          ? originalRates.list.slice(0, originalRates.list.length)
          : filteredRates.list.slice(0, originalRates.list.length);
        currentFilterRange.startIndex = 0;
        currentFilterRange.endIndex = originalRates.list.length;
      }
    },
    { deep: true },
  );

  watch(
    () => ratingsAndReviewsState.appsSelection,
    () => {
      fetchByFilters();
    },
    { deep: true },
  );

  const fetchByFilters = async () => {
    isLoading.value = true;
    const appsIDs = ratingsAndReviewsState.appsSelection.map((app) => app.id);
    const filterRes = await filterRatingsByApps(appsIDs);
    if (!filterRes.rates) {
      isLoading.value = false;
      return;
    }
    originalRates.list = filterRes.rates;
    const initialTableRecordCount = filterRes.rates.length >= 10 ? 10 : filterRes.rates.length;
    displayedRates.list = filterRes.rates.slice(0, initialTableRecordCount);
    currentFilterRange.startIndex = 0;
    currentFilterRange.endIndex = initialTableRecordCount;
    isLoading.value = false;
  };

  const applyPageRangeFilter = () => {
    let newRatesListEndIndex;

    if (!isFilterApplied.value) {
      newRatesListEndIndex =
        originalRates.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : originalRates.list.length;
      displayedRates.list = originalRates.list.slice(currentFilterRange.startIndex, newRatesListEndIndex);
    } else {
      newRatesListEndIndex =
        filteredRates.list.length >= currentFilterRange.endIndex
          ? currentFilterRange.endIndex
          : filteredRates.list.length;
      displayedRates.list = filteredRates.list.slice(currentFilterRange.startIndex, newRatesListEndIndex);
    }
  };

  const onSortASC = (sortingProperty: sortingStateProperties) => {
    switch (sortingProperty) {
      case sortingStateProperties.Rate:
        {
          const newRatesListEndIndex =
            originalRates.list.length >= currentFilterRange.endIndex
              ? currentFilterRange.endIndex
              : originalRates.list.length;
          filteredRates.list = [...originalRates.list.sort((a, b) => b.rate - a.rate)];
          displayedRates.list = filteredRates.list.slice(currentFilterRange.startIndex, newRatesListEndIndex);
          isFilterApplied.value = true;
          sortingState.rate = true;
          sortingState.totalRating = null;
        }
        break;
      case sortingStateProperties.TotalRating:
        {
          const newRatesListEndIndex =
            originalRates.list.length >= currentFilterRange.endIndex
              ? currentFilterRange.endIndex
              : originalRates.list.length;
          filteredRates.list = [...originalRates.list.sort((a, b) => b.app.total_rate - a.app.total_rate)];
          displayedRates.list = filteredRates.list.slice(currentFilterRange.startIndex, newRatesListEndIndex);
          isFilterApplied.value = true;
          sortingState.totalRating = true;
          sortingState.rate = null;
        }
        break;
      default:
        break;
    }
    sortingState[sortingProperty] = true;
  };

  const onSortDEC = (sortingProperty: sortingStateProperties) => {
    switch (sortingProperty) {
      case sortingStateProperties.Rate:
        {
          const newRatesListEndIndex =
            originalRates.list.length >= currentFilterRange.endIndex
              ? currentFilterRange.endIndex
              : originalRates.list.length;
          filteredRates.list = [...originalRates.list.sort((a, b) => a.rate - b.rate)];
          displayedRates.list = filteredRates.list.slice(currentFilterRange.startIndex, newRatesListEndIndex);
          isFilterApplied.value = true;
          sortingState.rate = false;
          sortingState.totalRating = null;
        }
        break;
      case sortingStateProperties.TotalRating:
        {
          const newRatesListEndIndex =
            originalRates.list.length >= currentFilterRange.endIndex
              ? currentFilterRange.endIndex
              : originalRates.list.length;
          filteredRates.list = [...originalRates.list.sort((a, b) => a.app.total_rate - b.app.total_rate)];
          displayedRates.list = filteredRates.list.slice(currentFilterRange.startIndex, newRatesListEndIndex);
          isFilterApplied.value = true;
          sortingState.totalRating = false;
          sortingState.rate = null;
        }
        break;
      default:
        break;
    }
    sortingState[sortingProperty] = false;
  };

  const onPreviousClick = () => {
    currentFilterRange.startIndex -= Number(ratingsAndReviewsState.selectedResultPerPage.value);
    currentFilterRange.endIndex -= Number(ratingsAndReviewsState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const onNextClick = () => {
    currentFilterRange.startIndex += Number(ratingsAndReviewsState.selectedResultPerPage.value);
    currentFilterRange.endIndex += Number(ratingsAndReviewsState.selectedResultPerPage.value);
    applyPageRangeFilter();
  };

  const isNextButtonDisabled = computed(() => {
    if (isFilterApplied.value) {
      return currentFilterRange.endIndex >= filteredRates.list.length;
    } else {
      return currentFilterRange.endIndex >= originalRates.list.length;
    }
  });

  return {
    appsOptions,
    ratingsAndReviewsState,
    sortingState,
    resultPerPage,
    v$,
    originalRates,
    filteredRates,
    displayedRates,
    currentFilterRange,
    isFilterApplied,
    isNextButtonDisabled,
    sortingStateProperties,
    isLoading,
    RatingsAndReviewsSelectProperties,
    onSelectionDataChange,
    onSortASC,
    onSortDEC,
    onPreviousClick,
    onNextClick,
  };
};

export default useRatingsAndReviews;
