import { defineComponent } from '@vue/composition-api';
import {
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableCell,
  ZidTableExpandable,
  ZidTableRow,
  ZidSwitch,
  ZidAlert,
  ZidButton,
  ZidModal,
  ZidLoader,
} from '@zidsa/ui';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import BadgeIcon from '@/assets/icons/domains/pd-admin/BadgeIcon.vue';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import DragIcon from '@/assets/icons/domains/pd-admin/DragIcon.vue';
import useSuggestedApps from '@/domains/pd-admin/helpers/administration/suggested-apps/useSuggestedApps';

export default defineComponent({
  components: {
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableCell,
    ZidTableExpandable,
    ZidTableRow,
    BaseText,
    ZidSwitch,
    ZidAlert,
    BaseIcon,
    BadgeIcon,
    ZidButton,
    ZidModal,
    Multiselect,
    draggable,
    DeleteIcon,
    DragIcon,
    ZidLoader,
  },
  setup() {
    return {
      ...useSuggestedApps(),
    };
  },
});
