import { ApplicationListingStatusProperty, ApplicationTypeProperty } from '@/api/models/ApplicationModel';

export enum AppsManagementInputProperty {
  SearchValue = 'searchValue',
}

export interface AppsManagementState {
  selectedResultPerPage: Record<string, string>;
  searchValue: string;
  appStatus: {
    label: string;
    value: AppStatusDropdownValuesEnum;
  };
  appType: {
    label: string;
    value: AppTypeDropdownValuesEnum;
  };
}

export type SortingStateType = {
  installs: null | boolean;
  rating: null | boolean;
};

export enum AppStatusDropdownEnum {
  AllStatus = 'All statuses',
  Published = 'Published',
  Unpublished = 'Unpublished',
  InReview = 'In Review',
  InDraft = 'In Draft',
}

export enum AppStatusDropdownValuesEnum {
  AllStatus = 0, // all statuses
  Published = 3,
  Unpublished = 4,
  InReview = 1,
  InDraft = '', // send 0
}

export enum AppTypeDropdownEnum {
  AllTypes = 'All Types',
  OAuth = 'OAuth 2.0',
  Shipping = 'Shipping',
  Javascript = 'Javascript',
}

export enum AppTypeDropdownValuesEnum {
  AllTypes = '',
  OAuth = 0,
  Shipping = 2,
  Javascript = 1,
}

export enum AppsManagementSelectProperties {
  SelectedResultPerPage = 'selectedResultPerPage',
  AppStatus = 'appStatus',
  AppType = 'appType',
}

export enum SortingStateProperties {
  Installs = 'installs',
  Rating = 'rating',
}

export interface ApplicationDataRowInterface {
  id: number;
  icon: string;
  name: string;
  price_model: string;
  zid_percentage: string;
  partner_name: string;
  type: ApplicationTypeProperty;
  type_label: string;
  installs_count: number;
  rate: number;
  status: ApplicationListingStatusProperty | null;
  status_label: string;
  app_category_id: number;
}

export type AdminAppsFilterParamsType = {
  appStatus: AppStatusDropdownValuesEnum | null;
  appType: AppTypeDropdownValuesEnum | null;
  searchValue: string | null;
};

export interface AdminAppsFilterParamsInterface {
  status?: AppStatusDropdownValuesEnum;
  type?: AppTypeDropdownValuesEnum;
  search?: string;
}
