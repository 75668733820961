import BaseModel from './BaseModel';
import ApplicationModel from './ApplicationModel';

class PublishedAppsResponseModel extends BaseModel {
  public payload: ApplicationModel[];

  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default PublishedAppsResponseModel;
