import httpClient from '../../httpClient';
import ErrorModel from '@/api/models/ErrorModel';
import PublishedAppsResponseModel from '@/api/models/PublishedAppsResponseModel';

const ENDPOINT_URL = '/market/partner-apps';

export const getApplicationsList = (): Promise<PublishedAppsResponseModel | ErrorModel> => {
  return httpClient
    .get(ENDPOINT_URL)
    .then((response) => new PublishedAppsResponseModel(response))
    .catch((error) => error);
};
