import Vue from 'vue';
import { reactive, onBeforeMount, ref } from '@vue/composition-api';
import {
  ApplicationDataRowInterface,
  AppStatusDropdownValuesEnum,
} from '@/domains/pd-admin/types/administration/apps-management/apps-management.type';
import {
  CategoryItemControlledInterface,
  CategorySelectModeEnum,
  SuggestedAppsStateInterface,
} from '@/domains/pd-admin/types/administration/suggested-apps/suggested-apps';
import { filterAdminApps } from '@/domains/pd-admin/api/administration/apps-management/apps-management';
import ErrorModel from '@/api/models/ErrorModel';
import { getCategories, updateCategory } from '@/domains/pd-admin/api/administration/suggested-apps/suggested-apps';

const useSuggestedApps = () => {
  const showAddSuggestedAppsModal = ref(false);
  const loadingCategoriesList = ref(false);
  const loadingAppsList = ref(false);
  const loadingSaveNewApps = ref(false);
  const loadingReorder = ref(false);
  const selectedCategoryID = ref(-1);
  const suggestedAppsMaxAllowed = ref(4);
  const categories = reactive<Record<string, CategoryItemControlledInterface[]>>({ list: [] });
  const originalAppsOptions: Record<string, ApplicationDataRowInterface[]> = reactive({ options: [] });
  const appsOptions: Record<string, ApplicationDataRowInterface[]> = reactive({ options: [] });
  const suggestedAppsState = reactive<SuggestedAppsStateInterface>({
    appsSelection: [],
  });

  onBeforeMount(async () => {
    loadingCategoriesList.value = true;
    const response = await getCategories();
    if (response instanceof ErrorModel || !response.payload) {
      loadingCategoriesList.value = false;
      return;
    }

    const modifiedRes = response.payload.map((cat) => ({ ...cat, inReorderMode: false }));

    categories.list = [...modifiedRes];

    loadingCategoriesList.value = false;

    loadingAppsList.value = true;

    const params = {
      appStatus: AppStatusDropdownValuesEnum.Published,
      appType: null,
      searchValue: null,
    };

    const allAppsRes = await filterAdminApps(params);
    if (allAppsRes instanceof ErrorModel || !allAppsRes.payload || !(allAppsRes.payload instanceof Array)) {
      loadingAppsList.value = false;
      return;
    }
    originalAppsOptions.options = [...allAppsRes.payload];
    appsOptions.options = [...originalAppsOptions.options];
    loadingAppsList.value = false;
  });

  const onSelectModeChange = async (switchValue: Record<string, boolean>, recordIndex: number) => {
    const categoryID = categories.list[recordIndex].id;
    const selectMode = switchValue.isSwitched ? CategorySelectModeEnum.AUTOMATIC : CategorySelectModeEnum.MANUAL;
    const selectedSuggestedAppsID = categories.list[recordIndex].suggested_apps.apps.map((app) => app.id);

    const updateCategoryRes = await updateCategory(categoryID, selectMode, selectedSuggestedAppsID);

    if (updateCategoryRes instanceof ErrorModel) {
      Vue.$toast.error('Could not update category select mode!');
      return;
    }

    categories.list[recordIndex].suggested_apps.is_auto = switchValue.isSwitched
      ? CategorySelectModeEnum.AUTOMATIC
      : CategorySelectModeEnum.MANUAL;
    Vue.$toast.success('updated category select mode successfully');
  };

  const onAddSuggestedAppClicked = (recordIndex: number) => {
    const currentSuggestedAppsLength = categories.list[recordIndex].suggested_apps.apps.length;
    if (currentSuggestedAppsLength) {
      appsOptions.options = [
        ...originalAppsOptions.options.filter(
          (option) => categories.list[recordIndex].suggested_apps.apps.findIndex((app) => app.id === option.id) === -1,
        ),
      ];
    }

    suggestedAppsMaxAllowed.value = suggestedAppsMaxAllowed.value - currentSuggestedAppsLength;
    selectedCategoryID.value = categories.list[recordIndex].id;
    showAddSuggestedAppsModal.value = true;
  };

  const onCloseSuggestedAppsModal = () => {
    resetAppsDropdown();
    showAddSuggestedAppsModal.value = false;
  };

  const onSaveSelectedAppsApps = async () => {
    loadingSaveNewApps.value = true;

    const categoryIndex = categories.list.findIndex((cat) => cat.id === selectedCategoryID.value);

    if (categoryIndex !== -1) {
      const category = categories.list[categoryIndex];
      const selectMode =
        category.suggested_apps.is_auto === CategorySelectModeEnum.AUTOMATIC
          ? CategorySelectModeEnum.AUTOMATIC
          : CategorySelectModeEnum.MANUAL;

      const selectedSuggestedAppsID = suggestedAppsState.appsSelection.map((app) => app.id);

      category.suggested_apps.suggested_ids = [
        ...category.suggested_apps.apps.map((app) => app.id),
        ...selectedSuggestedAppsID,
      ];

      const updateCategoryRes = await updateCategory(
        selectedCategoryID.value,
        selectMode,
        category.suggested_apps.suggested_ids,
      );

      if (updateCategoryRes instanceof ErrorModel) {
        Vue.$toast.error('Could not save selected apps');
        loadingSaveNewApps.value = false;
        return;
      }

      categories.list[categoryIndex].suggested_apps.apps = [
        ...categories.list[categoryIndex].suggested_apps.apps,
        ...suggestedAppsState.appsSelection,
      ];

      Vue.$toast.success('Saved suggested applications');
      suggestedAppsState.appsSelection = [];
      selectedCategoryID.value = -1;
      appsOptions.options = [...originalAppsOptions.options];
      suggestedAppsMaxAllowed.value = 4;
      loadingSaveNewApps.value = false;
      showAddSuggestedAppsModal.value = false;
    }
  };

  const onCancelAddingNewApps = () => {
    resetAppsDropdown();
    showAddSuggestedAppsModal.value = false;
  };

  const resetAppsDropdown = () => {
    appsOptions.options = [...originalAppsOptions.options];
    suggestedAppsState.appsSelection = [];
    selectedCategoryID.value = -1;
    suggestedAppsMaxAllowed.value = 4;
  };

  const onReorderSuggestedAppClicked = (categoryIndex: number) => {
    categories.list[categoryIndex].inReorderMode = true;
  };

  const onSubmitOrder = async (categoryIndex: number) => {
    loadingReorder.value = true;
    await updateSuggestedApps(categoryIndex);
    categories.list[categoryIndex].inReorderMode = false;
    loadingReorder.value = false;
  };

  const updateSuggestedApps = async (categoryIndex: number) => {
    const selectedSuggestedAppsID = categories.list[categoryIndex].suggested_apps.apps.map((app) => app.id);
    const categoryID = categories.list[categoryIndex].id;

    const selectMode =
      categories.list[categoryIndex].suggested_apps.is_auto === CategorySelectModeEnum.AUTOMATIC
        ? CategorySelectModeEnum.AUTOMATIC
        : CategorySelectModeEnum.MANUAL;

    const updateSuggestedAppsOrderRes = await updateCategory(categoryID, selectMode, selectedSuggestedAppsID);

    if (updateSuggestedAppsOrderRes instanceof ErrorModel) {
      Vue.$toast.error('Could not update suggested apps list');
      return false;
    }
    Vue.$toast.success('Updated suggested apps list');
    return true;
  };

  const onSuggestedAppDelete = async (
    suggestedAppsList: ApplicationDataRowInterface[],
    appID: number,
    categoryIndex: number,
  ) => {
    if (suggestedAppsList.length < 2 && !categories.list[categoryIndex].suggested_apps.is_auto) {
      Vue.$toast.error('At least one app should be selected, please switch to automatic mode');
      return;
    }
    const appIndex = categories.list[categoryIndex].suggested_apps.apps.findIndex((app) => app.id === appID);
    if (appIndex !== -1) {
      categories.list[categoryIndex].suggested_apps.apps.splice(appIndex, 1);
      await updateSuggestedApps(categoryIndex);
    }
  };

  return {
    categories,
    showAddSuggestedAppsModal,
    suggestedAppsState,
    suggestedAppsMaxAllowed,
    loadingCategoriesList,
    loadingAppsList,
    loadingReorder,
    appsOptions,
    CategorySelectModeEnum,
    onSelectModeChange,
    onAddSuggestedAppClicked,
    onCloseSuggestedAppsModal,
    onSaveSelectedAppsApps,
    onCancelAddingNewApps,
    onSuggestedAppDelete,
    onReorderSuggestedAppClicked,
    onSubmitOrder,
  };
};

export default useSuggestedApps;
