import httpClient from '@/api/httpClient';
import BaseModel from '@/api/models/BaseModel';
import ErrorModel from '@/api/models/ErrorModel';
import AppsCollectionsResponseModel from '@/domains/pd-admin/models/administration/apps-collections/apps-collections-response-model';
import CollectionResponseModel from '@/domains/pd-admin/models/administration/apps-collections/collection-response-model';
import UpdateCollectionResponseModel from '@/domains/pd-admin/models/administration/apps-collections/update-collection-response-model';
import { CollectionInfoInterface } from '@/domains/pd-admin/types/administration/apps-collections/manage-collection/manageCollection.type';

const ENDPOINT_URL = '/market/admin/app-collection';

export const getAllCollections = (): Promise<AppsCollectionsResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}`)
    .then((response) => new AppsCollectionsResponseModel(response))
    .catch((error) => error);
};

export const updateCollection = (
  collectionInfo: CollectionInfoInterface,
): Promise<UpdateCollectionResponseModel | ErrorModel> => {
  const collectionData = new FormData();
  if (collectionInfo.id) collectionData.append('id', `${collectionInfo.id}`);
  collectionData.append('status', collectionInfo.status);
  collectionData.append('name', collectionInfo.name);
  collectionData.append('name_ar', collectionInfo.name_ar);
  collectionData.append('description', collectionInfo.description);
  collectionData.append('description_ar', collectionInfo.description_ar);
  collectionData.append('short_description', collectionInfo.short_description);
  collectionData.append('short_description_ar', collectionInfo.short_description_ar);
  collectionData.append('label', collectionInfo.label);
  collectionData.append('label_ar', collectionInfo.label_ar);
  collectionData.append('quote', collectionInfo.quote);
  collectionData.append('quote_ar', collectionInfo.quote_ar);
  collectionData.append('order', collectionInfo.order);
  if (collectionInfo.image) collectionData.append('image', collectionInfo.image);
  if (collectionInfo.apps.length)
    collectionInfo.apps.forEach((app, index) => {
      collectionData.append(`apps[${index}]`, app);
    });

  return httpClient
    .post(`${ENDPOINT_URL}`, collectionData)
    .then((response) => new UpdateCollectionResponseModel(response))
    .catch((error) => error);
};

export const deleteCollection = (collectionID: string): Promise<BaseModel | ErrorModel> => {
  return httpClient
    .delete(`${ENDPOINT_URL}/${collectionID}`)
    .then((response) => new BaseModel(response))
    .catch((error) => error);
};

export const getCollection = (collectionID: number): Promise<CollectionResponseModel | ErrorModel> => {
  return httpClient
    .get(`${ENDPOINT_URL}/${collectionID}`)
    .then((response) => new CollectionResponseModel(response))
    .catch((error) => error);
};
