import BaseModel from '@/api/models/BaseModel';
import { CollectionInterface } from '@/domains/pd-admin/types/administration/apps-collections/collections-list/collectionsList.type';

class CollectionResponseModel extends BaseModel {
  public collection: CollectionInterface;
  constructor(arg: any) {
    super(arg);
    this.collection = arg.collection;
  }
}

export default CollectionResponseModel;
