import BaseModel from '@/api/models/BaseModel';
import { ApplicationDataRowInterface } from '../../../types/administration/apps-management/apps-management.type';

class AppsManagementResponseModel extends BaseModel {
  public payload: ApplicationDataRowInterface[];
  constructor(arg: any) {
    super(arg);
    this.payload = arg.payload;
  }
}

export default AppsManagementResponseModel;
