import { render, staticRenderFns } from "./MyApps.vue?vue&type=template&id=4c828c58&scoped=true&"
import script from "./myApps.ts?vue&type=script&lang=ts&"
export * from "./myApps.ts?vue&type=script&lang=ts&"
import style0 from "./MyApps.vue?vue&type=style&index=0&id=4c828c58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c828c58",
  null
  
)

export default component.exports