import { ApplicationDataRowInterface } from '../apps-management/apps-management.type';

export interface RatingsAndReviewsStateInterface {
  selectedResultPerPage: Record<string, string>;
  appsSelection: ApplicationDataRowInterface[];
}

export type SortingStateType = {
  rate: null | boolean;
  totalRating: null | boolean;
};

export enum RatingsAndReviewsSelectProperties {
  SelectedResultPerPage = 'selectedResultPerPage',
}

export enum sortingStateProperties {
  Rate = 'rate',
  TotalRating = 'totalRating',
}

export interface RateDataRowInterface {
  id: number;
  rate: number;
  body: string;
  user: string;
  created_at: string;
  created_at_timestamp: number;
  app: {
    name_en: string;
    total_rate: number;
  };
}
