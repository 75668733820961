import { render, staticRenderFns } from "./RatingsAndReviews.vue?vue&type=template&id=329417fa&scoped=true&"
import script from "./ratingsAndReviews.ts?vue&type=script&lang=ts&"
export * from "./ratingsAndReviews.ts?vue&type=script&lang=ts&"
import style0 from "./ratingsAndReviews.scss?vue&type=style&index=0&id=329417fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "329417fa",
  null
  
)

export default component.exports