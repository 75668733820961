import { getFeaturedApps } from '@/domains/pd-admin/api/administration/featured-apps/featured-apps';
import {
  AppOrderInterface,
  FeaturedAppDataRowInterface,
  FeaturedAppsStateInterface,
} from '@/domains/pd-admin/types/administration/featured-apps/featured-apps.type';
import { reactive, onBeforeMount, ref, watch } from '@vue/composition-api';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import { getAllAdminApps } from '@/domains/pd-admin/api/administration/apps-management/apps-management';
import { ApplicationDataRowInterface } from '@/domains/pd-admin/types/administration/apps-management/apps-management.type';
import { APPS_ORDERS, SHIPPING_APPS_ORDERS } from './dropdownInfo';
import { ApplicationListingStatusProperty, ApplicationTypeProperty } from '@/api/models/ApplicationModel';
import ErrorModel from '@/api/models/ErrorModel';

const useFeaturedApps = () => {
  const isLoadingAppsList = ref(false);
  const showAddOrEditFeaturedApp = ref(false);
  const showAddOrEditShippingFeaturedApp = ref(false);
  const isEditMode = ref(false);
  const isShippingApp = ref(false);
  const featuredAppsList = reactive<Record<string, FeaturedAppDataRowInterface[]>>({ list: [] });
  const originalApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const filteredApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const filteredShippingApps = reactive<Record<string, ApplicationDataRowInterface[]>>({
    list: [],
  });

  const appsOrders = reactive<Record<string, AppOrderInterface[]>>({
    list: APPS_ORDERS,
  });

  const shippingAppsOrders = reactive<Record<string, AppOrderInterface[]>>({
    list: SHIPPING_APPS_ORDERS,
  });

  onBeforeMount(async () => {
    await getFeaturedList();
    // all apps
    const allAppsRes = await getAllAdminApps();
    if (allAppsRes instanceof ErrorModel || !allAppsRes.payload) {
      return;
    }
    if (allAppsRes.payload && allAppsRes.payload instanceof Array) {
      originalApps.list = [
        ...allAppsRes.payload.filter(
          (app: ApplicationDataRowInterface) => app.status === ApplicationListingStatusProperty.Published,
        ),
      ];
      updateFilteredAppsListForSelection();
      updateFilteredShippingAppsListForSelection();
    }
    // all apps
  });

  const getFeaturedList = async (): Promise<void> => {
    isLoadingAppsList.value = true;
    // featured apps
    const featuredAppsRes = await getFeaturedApps();
    if (!featuredAppsRes.featured_apps) {
      isLoadingAppsList.value = false;
      featuredAppsList.list = [];
      return;
    }
    const list = featuredAppsRes.featured_apps?.sort((app: FeaturedAppDataRowInterface) => {
      if (app.type === ApplicationTypeProperty.Shipping) return -1;
      else return 1;
    });
    featuredAppsList.list = [...list];
    updateAppsOderListForSelection();
    updateShippingAppsOderListForSelection();
    // featured apps
    isLoadingAppsList.value = false;
  };

  const featuredAppsState = reactive<FeaturedAppsStateInterface>({
    appSelection: null,
    orderSelection: null,
    labelAr: null,
    labelEn: null,
  });

  const rules = {
    appSelection: {
      required,
      appSelection: helpers.withMessage('Please select an application', required),
    },
    orderSelection: {
      required,
      orderSelection: helpers.withMessage('Please select an application order', required),
    },
  };

  const v$ = useVuelidate(rules, featuredAppsState);

  const onInputDataChange = (changedValue: Record<string, string>, dataProperty: 'labelAr' | 'labelEn') => {
    featuredAppsState[dataProperty] = changedValue.value;
  };

  const updateAppsOderListForSelection = () => {
    const filtered = appsOrders.list.filter((appOrder) => {
      const appsList = featuredAppsList.list.map((app) => {
        if (app.type !== ApplicationTypeProperty.Shipping) return app;
      });
      const index = appsList.findIndex((featuredApp) => {
        return featuredApp?.order === appOrder.value;
      });
      if (index === -1) return true;
      else return false;
    });
    appsOrders.list = [...filtered];
  };

  const updateShippingAppsOderListForSelection = () => {
    const filtered = shippingAppsOrders.list.filter((appOrder) => {
      const shippingAppsList = featuredAppsList.list.map((app) => {
        if (app.type === ApplicationTypeProperty.Shipping) return app;
      });
      const index = shippingAppsList.findIndex((featuredApp) => {
        return featuredApp?.order === appOrder.value;
      });
      if (index === -1) return true;
      else return false;
    });

    shippingAppsOrders.list = [...filtered];
  };

  const updateFilteredAppsListForSelection = () => {
    const filtered = originalApps.list.filter((originalApp) => {
      const index = featuredAppsList.list.findIndex((featuredApp) => {
        return featuredApp.id === originalApp.id;
      });
      if (index === -1 && originalApp.status === ApplicationListingStatusProperty.Published) return true;
      else return false;
    });
    filteredApps.list = [...filtered];
  };

  const updateFilteredShippingAppsListForSelection = () => {
    const filtered = originalApps.list.filter((originalApp) => {
      const index = featuredAppsList.list.findIndex((featuredApp) => {
        return featuredApp.id === originalApp.id;
      });
      if (
        index === -1 &&
        originalApp.status === ApplicationListingStatusProperty.Published &&
        originalApp.type === ApplicationTypeProperty.Shipping
      )
        return true;
      else return false;
    });
    filteredShippingApps.list = [...filtered];
  };

  watch(
    () => featuredAppsState.appSelection,
    (app) => {
      if (app?.type === ApplicationTypeProperty.Shipping) {
        isShippingApp.value = true;
      }
    },
    { deep: true },
  );

  return {
    featuredAppsState,
    v$,
    isLoadingAppsList,
    showAddOrEditFeaturedApp,
    showAddOrEditShippingFeaturedApp,
    featuredAppsList,
    originalApps,
    filteredApps,
    filteredShippingApps,
    appsOrders,
    shippingAppsOrders,
    isEditMode,
    isShippingApp,
    APPS_ORDERS,
    SHIPPING_APPS_ORDERS,
    getFeaturedList,
    updateAppsOderListForSelection,
    updateFilteredAppsListForSelection,
    onInputDataChange,
    updateShippingAppsOderListForSelection,
    updateFilteredShippingAppsListForSelection,
  };
};

export default useFeaturedApps;
