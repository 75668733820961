import Vue from 'vue';
import { reactive, computed, onBeforeMount, ref } from '@vue/composition-api';
import useVuelidate from '@vuelidate/core';
import ErrorModel from '@/api/models/ErrorModel';
import { required } from '@vuelidate/validators';
import { ENGLISH_TEXT_WITH_SPECIAL_CHARS, ARABIC_TEXT_WITH_SPECIAL_CHARS } from '@/helpers/regex/text';
import { getFileFromURL } from '@/api/top-level-apis/helpers/fileFromURL';
import {
  ManageCollectionInputPropertiesEnum,
  ManageCollectionSelectPropertiesEnum,
  ManageCollectionStateInterface,
} from '@/domains/pd-admin/types/administration/apps-collections/manage-collection/manageCollection.type';
import { COMMA_SEPARATED_NUMBERS } from '@/helpers/regex/numbers';
import { getCollection } from '@/domains/pd-admin/api/administration/apps-collections/collection-list/collection-list';

const useManageCollection = (collectionID: number) => {
  const isEditMode = ref(collectionID != 0);
  const isLoadingCollection = ref(false);
  const isImageLoading = ref(false);
  const isImageInvalid = ref(false);
  const isSaveDraftLoading = ref(false);
  const isPublishLoading = ref(false);
  const collectionImage = reactive<Record<string, File | null>>({ image: null });

  const manageCollectionState = reactive<ManageCollectionStateInterface>({
    collectionNameAr: '',
    collectionNameEn: '',
    collectionOrder: {
      label: '',
      value: '',
    },
    collectionCardImage: '',
    collectionShortDescriptionAr: '',
    collectionShortDescriptionEn: '',
    collectionLabelAr: '',
    collectionLabelEn: '',
    collectionArticleAr: '',
    collectionArticleEn: '',
    collectionQuoteAr: '',
    collectionQuoteEn: '',
    collectionAppsIDs: '',
  });

  const rules = {
    collectionNameAr: {
      required,
      arabicContent: (value: string) => {
        return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
      },
    },
    collectionNameEn: {
      required,
      englishContent: (value: string) => {
        return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value);
      },
    },
    collectionOrder: {
      label: {
        required,
      },
      value: {
        required,
      },
    },
    collectionCardImage: {},
    collectionShortDescriptionAr: {
      required,
      arabicContent: (value: string) => {
        return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value);
      },
    },
    collectionShortDescriptionEn: {
      required,
      englishContent: (value: string) => {
        return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value);
      },
    },
    collectionLabelAr: {
      arabicContent: (value: string) => {
        return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value) || true;
      },
    },
    collectionLabelEn: {
      englishContent: (value: string) => {
        return (
          (value.length <= 20 && value.split(' ').length <= 2 && ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value)) || true
        );
      },
    },
    collectionArticleAr: {
      required,
    },
    collectionArticleEn: {
      required,
    },
    collectionQuoteAr: {
      arabicContent: (value: string) => {
        return ARABIC_TEXT_WITH_SPECIAL_CHARS.test(value) || true;
      },
    },
    collectionQuoteEn: {
      englishContent: (value: string) => {
        return ENGLISH_TEXT_WITH_SPECIAL_CHARS.test(value) || true;
      },
    },
    collectionAppsIDs: {
      required,
      collectionAppsIDs: (value: string) => {
        return COMMA_SEPARATED_NUMBERS.test(value);
      },
    },
  };

  const initialCollectionImage = reactive<Record<string, File | null>>({
    image: null,
  });

  const ORDER_OPTIONS_LIST = [];

  for (let i = 1; i <= 20; i++) {
    ORDER_OPTIONS_LIST.push({
      label: `${i}`,
      value: `${i}`,
    });
  }

  const collectionOrderDropdown = reactive({
    options: ORDER_OPTIONS_LIST,
  });

  const v$ = useVuelidate(rules, manageCollectionState);

  onBeforeMount(async () => {
    if (isEditMode.value) {
      isLoadingCollection.value = true;
      const collectionRes = await getCollection(collectionID);

      if (collectionRes instanceof ErrorModel) {
        isLoadingCollection.value = false;
        Vue.$toast.error('Failed to load collection details');
        return;
      }

      const { collection } = collectionRes;

      manageCollectionState.collectionNameAr = collection.name_ar ?? '';
      manageCollectionState.collectionNameEn = collection.name_en ?? '';
      manageCollectionState.collectionOrder = collectionOrderDropdown.options.find(
        (order: { value: string }) => order.value === `${collection.order}`,
      ) ?? { label: '', value: '' };

      manageCollectionState.collectionCardImage = collection.image;
      manageCollectionState.collectionShortDescriptionAr = collection.short_description_ar;
      manageCollectionState.collectionShortDescriptionEn = collection.short_description_en;
      manageCollectionState.collectionLabelAr = collection.label_ar;
      manageCollectionState.collectionLabelEn = collection.label_en;
      manageCollectionState.collectionArticleAr = collection.description_ar;
      manageCollectionState.collectionArticleEn = collection.description_en;
      manageCollectionState.collectionQuoteAr = collection.quote_ar;
      manageCollectionState.collectionQuoteEn = collection.quote_en;
      manageCollectionState.collectionAppsIDs = collection.apps;

      v$.value.$validate();

      isLoadingCollection.value = false;

      isImageLoading.value = true;
      const files = await getCollectionImageFile();
      collectionImage.image = files[0];
      initialCollectionImage.image =
        typeof manageCollectionState.collectionCardImage === 'string'
          ? new File([], manageCollectionState.collectionCardImage, {
              type: 'image/jpg',
            })
          : manageCollectionState.collectionCardImage;

      isImageLoading.value = false;
    }
  });

  const onInputDataChange = (
    changedValue: Record<string, string>,
    dataProperty: ManageCollectionInputPropertiesEnum,
  ) => {
    v$.value[dataProperty].$touch();
    manageCollectionState[dataProperty] = changedValue.value;
  };

  const onSelectionDataChange = (
    changedValue: Record<string, string>,
    property: ManageCollectionSelectPropertiesEnum,
  ) => {
    v$.value[property].$touch();
    const selectedValue = { ...changedValue };
    manageCollectionState[property].label = selectedValue.label;
    manageCollectionState[property].value = selectedValue.value;
  };

  const getCollectionImageFile = async (): Promise<File[]> => {
    if (typeof manageCollectionState.collectionCardImage === 'string') {
      const filePromise = await getFileFromURL(
        manageCollectionState.collectionCardImage,
        manageCollectionState.collectionCardImage,
        'image/jpg',
      );
      return Promise.all([filePromise])
        .then((files) => files)
        .catch(() => {
          manageCollectionState.collectionCardImage = '';
          isImageLoading.value = false;
          isImageInvalid.value = true;
          return [];
        });
    } else return [manageCollectionState.collectionCardImage];
  };

  const onCollectionImageChange = (image: File[]) => {
    collectionImage.image = image[0];

    const isFile = collectionImage.image.type.includes('image/');

    if (!isFile) {
      isImageInvalid.value = true;
    } else {
      isImageInvalid.value = false;
    }
  };

  const onCollectionImageRemove = () => {
    collectionImage.image = null;
    isImageInvalid.value = true;
  };

  const isImageFileValid = computed(() => !!collectionImage.image);

  const isPublishDisabled = computed(() => {
    return (
      isImageInvalid.value ||
      v$.value.collectionNameAr.$invalid ||
      v$.value.collectionNameEn.$invalid ||
      v$.value.collectionOrder.$invalid ||
      v$.value.collectionCardImage.$invalid ||
      v$.value.collectionShortDescriptionAr.$invalid ||
      v$.value.collectionShortDescriptionEn.$invalid ||
      v$.value.collectionLabelAr.$invalid ||
      v$.value.collectionLabelEn.$invalid ||
      v$.value.collectionArticleAr.$invalid ||
      v$.value.collectionArticleEn.$invalid ||
      v$.value.collectionQuoteAr.$invalid ||
      v$.value.collectionQuoteEn.$invalid ||
      v$.value.collectionAppsIDs.$invalid
    );
  });

  return {
    manageCollectionState,
    collectionOrderDropdown,
    v$,
    isLoadingCollection,
    isEditMode,
    isSaveDraftLoading,
    isPublishLoading,
    initialCollectionImage,
    isPublishDisabled,
    isImageLoading,
    isImageInvalid,
    collectionImage,
    isImageFileValid,
    ManageCollectionInputPropertiesEnum,
    ManageCollectionSelectPropertiesEnum,
    onCollectionImageChange,
    onCollectionImageRemove,
    onInputDataChange,
    onSelectionDataChange,
  };
};

export default useManageCollection;
