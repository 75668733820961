import Vue from 'vue';
import { defineComponent } from '@vue/composition-api';
import router from '@/router';
import ErrorModel from '@/api/models/ErrorModel';
import { RoutingRouteEnum } from '@/router/routes.enum';
import BaseText from '@/components/text/BaseText.vue';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import {
  ZidLoader,
  ZidButton,
  ZidTable,
  ZidTableRowGroup,
  ZidTableHeader,
  ZidTableRow,
  ZidTableCell,
  ZidPageHeader,
  ZidBreadcrumb,
  ZidBreadcrumbItem,
  ZidIcon,
  ZidConfirmModal,
} from '@zidsa/ui';
import DeleteIcon from '@/assets/icons/applications/DeleteIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import useCollectionsList from '@/domains/pd-admin/helpers/administration/apps-collections/collections-list/useCollectionsList';
import { CollectionInterface } from '@/domains/pd-admin/types/administration/apps-collections/collections-list/collectionsList.type';
import { CollectionStatusEnum } from '@/domains/pd-admin/types/administration/apps-collections/manage-collection/manageCollection.type';
import { deleteCollection } from '@/domains/pd-admin/api/administration/apps-collections/collection-list/collection-list';

export default defineComponent({
  components: {
    BaseText,
    BaseIcon,
    ZidLoader,
    ZidButton,
    ZidTable,
    ZidTableRowGroup,
    ZidTableHeader,
    ZidTableRow,
    ZidTableCell,
    ZidPageHeader,
    ZidBreadcrumb,
    ZidBreadcrumbItem,
    ZidIcon,
    DeleteIcon,
    EditIcon,
    ZidConfirmModal,
  },
  setup() {
    const {
      isLoadingCollectionsList,
      originalCollections,
      confirmDeleteCollectionState,
      resetConfirmDeleteModalState,
      fetchCollections,
    } = useCollectionsList();

    const onCreateNewCollection = (): void => {
      router.push({ name: RoutingRouteEnum.Admin_ManageCollection }).catch(() => {
        //
      });
    };

    const onCollectionEdit = (collection: CollectionInterface) => {
      router.push({ name: RoutingRouteEnum.Admin_ManageCollection, params: { id: `${collection.id}` } }).catch(() => {
        //
      });
    };

    const onDeleteCollection = (collection: CollectionInterface) => {
      confirmDeleteCollectionState.isOpen = true;
      confirmDeleteCollectionState.id = `${collection.id}`;
    };

    const onDeleteCollectionConfirmed = async (): Promise<void> => {
      if (confirmDeleteCollectionState.id) {
        confirmDeleteCollectionState.isLoading = true;
        const res = await deleteCollection(confirmDeleteCollectionState.id);
        if (res instanceof ErrorModel) {
          Vue.$toast.error('Could not delete collection!');
          confirmDeleteCollectionState.isLoading = false;
          return;
        }
        await fetchCollections();
        confirmDeleteCollectionState.isLoading = false;
        confirmDeleteCollectionState.isOpen = false;
        Vue.$toast.success('Collection has been deleted');
      }
    };

    return {
      RoutingRouteEnum,
      isLoadingCollectionsList,
      originalCollections,
      confirmDeleteCollectionState,
      CollectionStatusEnum,
      onCreateNewCollection,
      onCollectionEdit,
      onDeleteCollection,
      onDeleteCollectionConfirmed,
      resetConfirmDeleteModalState,
    };
  },
});
