import BaseModel from '@/api/models/BaseModel';
import { CollectionInterface } from '@/domains/pd-admin/types/administration/apps-collections/collections-list/collectionsList.type';

class AppsCollectionsResponseModel extends BaseModel {
  public collections: CollectionInterface[];
  constructor(arg: any) {
    super(arg);
    this.collections = arg.collections;
  }
}

export default AppsCollectionsResponseModel;
