import Vue from 'vue';
import { defineComponent, reactive, onBeforeMount, ref } from '@vue/composition-api';
import BaseText from '@/components/text/BaseText.vue';
import {
  ZidButton,
  ZidCard,
  ZidCardHeader,
  ZidCardBody,
  ZidIcon,
  ZidLoader,
  ZidInput,
  ZidBadge,
  ZidTable,
  ZidTableRow,
  ZidTableCell,
  ZidTableHeader,
  ZidTableRowGroup,
} from '@zidsa/ui';
import router from '@/router/index';
import { RoutingRouteEnum } from '@/router/routes.enum';
import { getApplicationsList } from '@/api/top-level-apis/dashboard/applicationsList';
import ApplicationModel from '@/api/models/ApplicationModel';
import dayjs from 'dayjs';
import BaseIcon from '@/components/base-icon/BaseIcon.vue';
import EditIcon from '@/assets/icons/applications/EditIcon.vue';
import RollbackIcon from '@/assets/icons/applications/RollbackIcon.vue';
import SearchIcon from '@/assets/icons/applications/SearchIcon.vue';
import { ApplicationTypeProperty, ApplicationListingStatusProperty } from '@/api/models/ApplicationModel';
import { AppTypeProperty } from '@/types/global-types/appTypes.enum';
import ErrorModel from '@/api/models/ErrorModel';

export default defineComponent({
  components: {
    BaseText,
    ZidButton,
    ZidCard,
    ZidCardHeader,
    ZidCardBody,
    ZidIcon,
    ZidLoader,
    BaseIcon,
    EditIcon,
    ZidInput,
    ZidBadge,
    SearchIcon,
    RollbackIcon,
    ZidTable,
    ZidTableRow,
    ZidTableCell,
    ZidTableHeader,
    ZidTableRowGroup,
  },
  setup() {
    const appsList = reactive<Record<string, ApplicationModel[]>>({ data: [] });
    const filteredAppsList = reactive<Record<string, ApplicationModel[]>>({ data: [] });
    const loadingAppsList = ref(false);
    const searchInputValue = ref('');
    const DRAFT = null;

    onBeforeMount(() => {
      loadApplicationsList();
    });

    const loadApplicationsList = async () => {
      loadingAppsList.value = true;
      const response = await getApplicationsList();
      if (response instanceof ErrorModel || !response.payload) {
        Vue.$toast.error('Failed to load Applications');
        loadingAppsList.value = false;
        return;
      }
      const { payload } = response;
      if (payload.length > 0) {
        appsList.data = [...payload];
        filteredAppsList.data = [...payload];
      }
      loadingAppsList.value = false;
    };

    const onSearchInputChange = (changedValue: Record<string, string>) => {
      searchInputValue.value = changedValue.value;
      if (searchInputValue.value.length) {
        const newFilteredList = appsList.data.filter((application) =>
          application.name.toLowerCase().includes(searchInputValue.value.toLowerCase()),
        );
        filteredAppsList.data = [...newFilteredList];
      } else {
        filteredAppsList.data = [...appsList.data];
      }
    };

    const onCreateAppClicked = () => {
      router
        .push({
          name: RoutingRouteEnum.Admin_CreateApplication,
        })
        .catch((err) => {
          /**/
        });
    };

    const onEditClicked = (application: ApplicationModel) => {
      if (application.type === ApplicationTypeProperty.OAuth) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_OAuth,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      } else if (application.type === ApplicationTypeProperty.Javascript) {
        router
          .push({
            name: RoutingRouteEnum.Admin_AppDetails_JS,
            params: {
              id: `${application.id}`,
            },
          })
          .catch((err) => {
            /**/
          });
      }
    };

    return {
      appsList,
      filteredAppsList,
      dayjs,
      DRAFT,
      loadingAppsList,
      searchInputValue,
      AppTypeProperty,
      ApplicationListingStatusProperty,
      ApplicationTypeProperty,
      onCreateAppClicked,
      onEditClicked,
      onSearchInputChange,
    };
  },
});
