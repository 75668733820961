import {
  AppOrdersEnum,
  AppOrdersValuesEnum,
} from '@/domains/pd-admin/types/administration/featured-apps/featured-apps.type';

export const APPS_ORDERS = [
  {
    label: AppOrdersEnum.FirstApp,
    value: AppOrdersValuesEnum.FirstApp,
  },
  {
    label: AppOrdersEnum.SecondApp,
    value: AppOrdersValuesEnum.SecondApp,
  },
  {
    label: AppOrdersEnum.ThirdApp,
    value: AppOrdersValuesEnum.ThirdApp,
  },
  {
    label: AppOrdersEnum.FourthApp,
    value: AppOrdersValuesEnum.FourthApp,
  },
  {
    label: AppOrdersEnum.FifthApp,
    value: AppOrdersValuesEnum.FifthApp,
  },
];

export const SHIPPING_APPS_ORDERS = [
  {
    label: AppOrdersEnum.FirstApp,
    value: AppOrdersValuesEnum.FirstApp,
  },
  {
    label: AppOrdersEnum.SecondApp,
    value: AppOrdersValuesEnum.SecondApp,
  },
  {
    label: AppOrdersEnum.ThirdApp,
    value: AppOrdersValuesEnum.ThirdApp,
  },
  {
    label: AppOrdersEnum.FourthApp,
    value: AppOrdersValuesEnum.FourthApp,
  },
];
