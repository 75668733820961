import BaseModel from '@/api/models/BaseModel';
import { CollectionInterface } from '@/domains/pd-admin/types/administration/apps-collections/collections-list/collectionsList.type';

class UpdateCollectionResponseModel extends BaseModel {
  public appCollection: CollectionInterface;
  constructor(arg: any) {
    super(arg);
    this.appCollection = arg.appCollection;
  }
}

export default UpdateCollectionResponseModel;
